import { classNames } from "@billy/lib";
import { Button, Logo, Shell } from "@billy/ui";
import { signIn } from "next-auth/react";
import Head from "next/head";
import { FaGoogle } from "react-icons/fa";

export default function Index() {
  return (
    <div className="flex min-h-screen flex-col justify-center bg-[#f3f4f6] py-12 sm:px-6 lg:px-8">
      <Head>
        <title>Login | billy</title>
      </Head>
      <div
        className={classNames("text-center sm:mx-auto sm:w-full sm:max-w-md")}
      >
        <div className="flex justify-center mb-4">
          <Logo />
        </div>
        <h2 className="font-cal text-emphasis text-center text-3xl font-medium">
          Welcome back
        </h2>
      </div>
      <div className="mb-auto mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white border-subtle mx-2 rounded-md border px-4 py-10 sm:px-10">
          <Button
            color="secondary"
            className="w-full justify-center"
            StartIcon={FaGoogle}
            onClick={async (e) => {
              e.preventDefault();
              await signIn("google");
            }}
          >
            Sign in with Google
          </Button>
        </div>
      </div>
    </div>
  );
}
